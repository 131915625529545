import * as Sentry from "@sentry/react";

export function checkResponse(res) {
  if (res.data.error_code) {
    throw new Error(res.data.error_code);
  } else {
    const clientToken = res.headers["x-client-token"];

    window.localStorage.setItem("client_token", clientToken);
    window.axios.defaults.headers.common["X-Client-Token"] = clientToken;

    Sentry.setUser({token: clientToken});

    return res.data;
  }
}

export function createPhoto(image, data) {
  const formData = new FormData();

  if (image instanceof File) {
    formData.append("file", image);
  } else {
    formData.append("image_url", image);
  }

  if (data) {
    formData.append("image_data", JSON.stringify(data || {}));
  }

  return window.axios.post(window.appConfig.paths.apiUpload + "/photos/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }).then(checkResponse);
}

export function fetchPhotoById(id) {
  return window.axios.get(window.appConfig.paths.api + "/photos/id/" + id)
    .then(checkResponse);
}

export function fetchPhotoByHash(hash) {
  return window.axios.get(window.appConfig.paths.api + "/photos/hash/" + hash)
    .then(checkResponse);
}

export function refreshPhotoGroup(id) {
  return window.axios.post(window.appConfig.paths.api + "/photos/refresh-group", {id})
    .then(checkResponse);
}

export function logPhotoShare(id, provider) {
  return window.axios.post(window.appConfig.paths.api + "/photos/log-share", {id, provider})
    .then(checkResponse);
}