function WebviewEventHolder(initialValue) {
  this.value = initialValue;
  this.subscribers = [];
}

WebviewEventHolder.prototype.subscribe = function(listener, emitNow) {
  emitNow = emitNow || false;

  const subscriber = new WebviewEventSubscriber(this, listener);
  this.subscribers.push(subscriber);

  if (emitNow) {
    subscriber.dispatch(this.value);
  }

  return subscriber;
};

WebviewEventHolder.prototype.unsubscribe = function(subscriber) {
  const pos = this.subscribers.indexOf(subscriber);
  if (pos > -1) {
    this.subscribers.splice(pos, 1);
  }
};

WebviewEventHolder.prototype.postValue = function(value) {
  this.value = value;
  for (let i = 0; i < this.subscribers.length; i++) {
    this.subscribers[i].dispatch(value);
  }
};

function WebviewEventSubscriber(holder, listener) {
  this.holder = holder;
  this.listener = listener;
}

WebviewEventSubscriber.prototype.dispatch = function(value) {
  this.listener(value);
};

WebviewEventSubscriber.prototype.unsubscribe = function() {
  this.holder.unsubscribe(this);
};

window.webviewHolders = {
  tabSelected: new WebviewEventHolder(0),
  deeplinkParams: new WebviewEventHolder(0),
};

// ---

window.webview_tab_selected = function() {
  window.webviewHolders.tabSelected.postValue(Date.now());
};

window.webviewDeeplinkParams = function(data) {
  window.webviewHolders.deeplinkParams.postValue(data);
};

// ---

window.backPressStack = [];
window.pushOnBackPressed = (cb) => window.backPressStack.push(cb);
window.popOnBackPressed = () => window.backPressStack.pop();
window.onBackPressed = () => {
  if (window.backPressStack.length === 0) {
    return false;
  }

  return window.backPressStack[window.backPressStack.length - 1]() || false;
};