const STATUS_FAILED = -1;
const STATUS_PENDING = 0;
const STATUS_PROCESSING = 1;
const STATUS_PROCESSED = 2;

export function creativeIsProcessed(creative) {
  return creative.status === STATUS_PROCESSED;
}

export function creativeIsFailed(creative) {
  return creative.status === STATUS_FAILED;
}

export function creativeIsProcessing(creative) {
  return creative.status === STATUS_PENDING || creative.status === STATUS_PROCESSING;
}