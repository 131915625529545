import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import * as api from "../utils/api";
import Loading from "../components/Loading";
import FileChooseButton from "../components/FileChooseButton";
import HomeButton from "../components/HomeButton";
import AppContext from "../contexts/AppContext";
import * as creativeUtils from "../utils/creative";
import {getLocationQueryObject} from "../utils/text";
import {logEvent, userEvents} from "../utils/log";
import * as webviewUtils from "../utils/webview";
import ImageView from "../components/ImageView";

const FETCH_INTERVAL = 1000;
const GROUP_COLLAGE = "collage";

export default class ResultPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      defaultGroup: null,
      photo: null,
      creatives: [],
      refreshes: 0,
    };

    this.fetchResult = this.fetchResult.bind(this);
    this.handleResultFetched = this.handleResultFetched.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.handleDownloadButtonClick = this.handleDownloadButtonClick.bind(this);
    this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind(this);

    this.fecthTimer = null;
  }

  componentDidMount() {
    this.fetchResult();
  }

  componentWillUnmount() {
    clearTimeout(this.fecthTimer)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.hash !== prevProps.match.params.hash) {
      this.setState({isLoading: true}, this.fetchResult);
    }
  }

  fetchResult() {
    api.fetchPhotoByHash(this.props.match.params.hash)
      .then(this.handleResultFetched)
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  handleResultFetched(res) {
    const query = getLocationQueryObject();
    const isNewResult = this.state.photo === null || this.state.photo.id !== res.photo.id;
    const nextState = {
      isLoading: false,
      photo: res.photo,
      creatives: res.creatives,
    };

    if (isNewResult) {
      nextState.defaultGroup = res.photo.group;
      nextState.refreshes = 0;

      logEvent(userEvents.PAGE_RESULT, {
        photo_id: nextState.photo.id,
        is_owner: nextState.photo.is_owner,
        ref: nextState.photo.is_owner ? undefined : (query.ref || "unspecified")
      });
    }

    let cb = undefined;

    if (nextState.creatives.filter(creativeUtils.creativeIsProcessing).length > 0) {
      cb = () => {
        this.fecthTimer = setTimeout(this.fetchResult, FETCH_INTERVAL);
      };
    }

    this.setState(nextState, cb);
  }

  handleFileSelected(file) {
    logEvent(userEvents.PHOTO_SELECT, {page: "result"});

    this.props.history.push(routes.CREATE, {file});
  }

  handleDownloadButtonClick(e, creative) {
    logEvent(userEvents.DOWNLOAD, {
      photo_id: this.state.photo.id,
      collage_template_id: creative.template.id,
      group: this.state.photo.group,
      default_group: this.state.defaultGroup,
      refreshes: this.state.refreshes,
    });

    setTimeout(() => {
      if (window.clientConfig.isWebview) {
        webviewUtils.webviewShareDownload(creative.file.url);
      } else {
        const url = new URL(this.state.photo.download_url);
        url.searchParams.set("creative_id", creative.id);
        window.location.href = url.toString();
      }
    }, 100);
  }

  handleRefreshButtonClick() {
    logEvent(userEvents.GROUP_REFRESH, {
      group: this.state.photo.group,
      default_group: this.state.defaultGroup,
      refreshes: this.state.refreshes,
    });

    this.setState({refreshes: this.state.refreshes + 1});

    api.refreshPhotoGroup(this.state.photo.id).then(this.handleResultFetched);
  }

  render() {
    const creative = this.state.creatives
      .filter((item) => item.group === GROUP_COLLAGE)
      .find((item) => item.is_selected);

    const creativeIsProcessing = !creative || creativeUtils.creativeIsProcessing(creative);

    if (this.state.isLoading || creativeIsProcessing) {
      const photo = (this.state.photo && this.state.photo.file && this.state.photo.file.url)
        || (this.props.location.state && this.props.location.state.photo && this.props.location.state.photo.file && this.props.location.state.photo.file.url);

      return <Loading image={photo} />;
    }

    const isOwner = this.state.photo.is_owner;
    const isGuest = !isOwner;

    return <main className="collage-page">
      <div className="container">
        <HomeButton hidden={isGuest} page={"result"} />
        <h1 className="main-title" dangerouslySetInnerHTML={{__html: i18n.t("collage__title")}} />
        <p className="collage-page-text" dangerouslySetInnerHTML={{__html: i18n.t("collage__text_guest")}} />
      </div>
      <div className="collage-page-content">
        <div>
          <div className="creative-holder">
            <ImageView image={creative.file} backgrounded />
          </div>
          <div className="btns-container">
            <button
              className="btn-upload-foto"
              children={i18n.t("collage__download")}
              hidden={creativeIsProcessing || isGuest}
              onClick={(e) => this.handleDownloadButtonClick(e, creative)} />
            <button
              className="btn-refresh-foto"
              hidden={isGuest || creativeIsProcessing}
              onClick={this.handleRefreshButtonClick}>
              <SvgRefreshIcon />
              <span children={i18n.t("collage__refresh")} />
            </button>
          </div>
        </div>
      </div>

      <div className="container" hidden={isOwner}>
        <FileChooseButton
          onFileSelected={this.handleFileSelected}
          className="btn-upload-foto"
          children={i18n.t("collage__select_photo")} />
      </div>
    </main>;
  }
}

ResultPage.contextType = AppContext;

function SvgRefreshIcon() {
  return <svg viewBox="0 0 72 71">
    <g fill="none" fillRule="evenodd">
      <g fill="#3d24b0" fillRule="nonzero" stroke="#3d24b0" strokeWidth="2.125">
        <g>
          <g>
            <path d="M33.786 3.4c5.344 0 10.696 1.517 15.477 4.387 3.033 1.824 5.759 4.118 8.074 6.796l-7.988-.264c-.938-.032-1.725.705-1.755 1.643-.031.938.704 1.724 1.643 1.755l11.052.365c.635.262 1.366.115 1.85-.371.486-.486.63-1.216.368-1.851l-.363-11.05c-.022-.938-.8-1.682-1.739-1.66-.938.02-1.683.798-1.662 1.736l.002.038.21 6.375c-2.339-2.5-5.012-4.664-7.942-6.428C45.703 1.683 39.746 0 33.786 0 24.266 0 15.49 3.925 9.075 11.052 3.393 17.367 0 25.864 0 33.788c0 .938.76 1.7 1.7 1.7s1.7-.762 1.7-1.7c0-7.104 3.066-14.753 8.202-20.459C17.364 6.926 25.241 3.4 33.787 3.4zM66.3 32.086c-.94 0-1.7.761-1.7 1.7 0 7.104-3.066 14.753-8.202 20.46-5.761 6.401-13.64 9.927-22.184 9.927-5.344 0-10.696-1.516-15.478-4.386-3.032-1.825-5.758-4.12-8.073-6.797l7.99.264c.937.03 1.723-.705 1.753-1.644.031-.938-.704-1.724-1.642-1.755L7.71 49.49c-.635-.261-1.366-.114-1.85.371-.486.486-.631 1.216-.368 1.85l.363 11.05c.022.94.8 1.683 1.739 1.662.938-.02 1.683-.8 1.662-1.738l-.002-.036-.21-6.375c2.339 2.5 5.01 4.663 7.942 6.428 5.31 3.187 11.267 4.87 17.227 4.87 9.52 0 18.297-3.924 24.711-11.051C64.607 50.206 68 41.708 68 33.786c0-.939-.76-1.7-1.7-1.7h0z" transform="translate(-1005 -1759) translate(988 1761) translate(19)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>;
}